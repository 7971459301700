export enum ProductImages {
  PiBox = 'piBox',
  Kiosk = 'kiosk',
  Honeywell = 'honeywell',
  PiMobile = 'piMobile',
  KioskPlus = 'kioskPlus',
}

export enum ProductNames {
  PiBox = 'Pibox Scanner',
  Kiosk = 'Kiosk Scanner',
  Honeywell = 'Handheld Scanner',
  PiMobile = 'Patronscan App',
  KioskPlus = 'Kiosk Plus',
}

export interface ProductCard {
  image: ProductImages;
  productName: string;
  description?: string;
  ctaText?: string;
  ctaButtonId?: string;
  bgColor?: string;
  anchor?: string;
}
