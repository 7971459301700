import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Hero from '../components/sections/hero';
import Feature from '../components/sections/industry/feature-benefit';
import FeatureBullet from '../components/sections/industry/feature-bullet';
import Testimonials from '../components/sections/testimonials';
import { TestimonialCard } from '../models/testimonial-card';
import WhyPatronscan from '../components/sections/why-patronscan';
import SEO from '../components/seo';
import './id-scanner-for-bars.css';
import ProductGrid from '../components/sections/product-grid';
import { ProductCard, ProductImages, ProductNames } from '../models/product-card';
import LeadCapture from '../components/sections/lead-capture';

const SafeOrlandoLandingPage = () => {
  const data = useStaticQuery(graphql`
    query {
      orlandoHero: file(relativePath: { eq: "hero-images/orlando-banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      downtownOrlando: file(relativePath: { eq: "landing-pages/orlando-downtown-map.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      handheld: file(relativePath: { eq: "benefit-images/handheld.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      homescreenKiosk: file(relativePath: { eq: "benefit-images/kiosk-scanner.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      dashboard: file(relativePath: { eq: "entertainment/live_statistics.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const testimonials: TestimonialCard[] = [
    {
      testimonialVideo: 'https://player.vimeo.com/video/442852613',
      quote:
        '“When I first took over, we had serious challenges, we encountered a lot of lawsuits and Patronscan was able to mitigate them.”',
      fullName: 'Chris “Sarge” Curtis',
      positionTitle: 'Director of the Downtown Rangers',
      location: 'Las Vegas, Fremont Street.',
    },
    {
      quote:
        '“Looking back over the years, my only regret is I didn’t sign up sooner with Patronscan. There have been so many situations that happened that could have been easily prevented. Although I don’t work for Patronscan, I just love your product"',
      fullName: 'Susan Durst',
      positionTitle: 'Press Club - Sacramento',
      location: 'Sacramento, CA',
    },
    {
      quote:
        '"The ID scanners are unashamedly about stopping the troublemakers at the door, preventing incidents before they happen"',
      fullName: 'Paul Newson',
      positionTitle: 'Officer of Liquor, Gaming and Racing',
      location: 'Kings Cross, Sydney',
    },
  ];

  const products: ProductCard[] = [
    {
      image: ProductImages.Kiosk,
      productName: ProductNames.Kiosk,
      description: 'Best for bars and nightclubs',
      ctaText: 'Get Pricing',
      anchor: '/safe-orlando-id-scanners/#inquire',
      ctaButtonId: 'SafeOrlandoProductCard:Kiosk',
    },
    {
      image: ProductImages.Honeywell,
      productName: ProductNames.Honeywell,
      description: 'Used for secondary entrances',
      ctaText: 'Get Pricing',
      anchor: '/safe-orlando-id-scanners/#inquire',
      ctaButtonId: 'SafeOrlandoProductCard:Honeywell',
    },
  ];

  return (
    <div>
      <SEO
        title="ID Scanners for Orlando's Bars &amp; Nightclubs"
        description="The front line for bars and nightclubs. Get $10,000 in funding, spot troublemakers and catch fake IDs. 24/7 support."
      />
      <Hero
        heroImage={data.orlandoHero.childImageSharp.fluid}
        darkBackground
        heading="The Ultimate Nightlife Safety Solution"
        subHeading="Receive up to $10,000 with the Safety Awareness for Everyone (S.A.F.E.) program. "
        videoLink="https://player.vimeo.com/video/569018228?autoplay=0&loop=0&autopause=0&muted=0"
        showVideo
        landing
        linkText="Learn More"
        anchor="/safe-orlando-id-scanners/#get-started"
        buttonId="SafeOrlandoHero:LearnMore"
      />
      <FeatureBullet
        image={data.downtownOrlando.childImageSharp.fluid}
        backgroundColor="blue-orlando"
        firstBullet="Proven to reduce criminal activity and nightlife incidents by as much as 96%"
        secondBullet="Approved by Orlando's Safety Awareness for Everyone (S.A.F.E) Program"
        thirdBullet="Spot fake IDs, underaged patrons and pass-backs with trusted ID scanners"
        fourthBullet="Up to $10,000 in funding to eligible businesses including bars and nightclubs located within the CRA"
        heading="S.A.F.E. Program &amp; Patronscan"
        textColor="grey-orlando"
      />
      <h1 className="text-center text-grey-orlando font-bold text-5xl mt-24">
        Patronscan Benefits
      </h1>
      <Feature
        image={data.homescreenKiosk.childImageSharp.fluid}
        heading="Spot minors and"
        headingThin="catch fake IDs"
        subHeading="Verify age and use the best fake ID detection technology developed over the last 15 years."
        description="Patronscan analyzes over 6,000 data points to determine an ID's authenticity. Verify over 4,500 IDs from around the world, including all driver's licenses and passports with near-zero false positives."
        linkText="Get Started"
        anchorLink="/safe-orlando-id-scanners/#get-started"
        buttonId="SafeOrlandoFeature:SpotMinors"
      />
      <Feature
        rightAlign
        image={data.handheld.childImageSharp.fluid}
        heading="The bouncer that"
        headingThin="never forgets a face"
        subHeading="Spot trouble from 50,000+ individuals known for assaults, chargebacks, drugs and property damage."
        description="Reduce nightlife incidents by as much as 97% by spotting trouble before it becomes a problem. Receive alerts when troublemakers scan their ID including details on why they've been flagged."
        linkText="Learn More"
        anchorLink="/safe-orlando-id-scanners/#get-started"
        buttonId="SafeOrlandoFeature:BouncerThatNeverForgets"
      />
      <Feature
        image={data.dashboard.childImageSharp.fluid}
        heading="Get peace of mind"
        headingThin="for pennies an hour"
        subHeading="Receive security alerts when flagged patrons, inspectors or minors arrive at the door."
        description="Keep a pulse on your venue with live stats sent to your phone. Access patron history to flag individuals, review your demographics and register members."
        linkText="Get Started"
        anchorLink="/safe-orlando-id-scanners/#get-started"
        buttonId="SafeOrlandoFeature:GetPeaceofMind"
      />
      <Testimonials testimonials={testimonials} />
      <a className="anchor" id="get-started"></a>
      <ProductGrid
        offeringTitle="Protect your profit, people and license"
        offeringSubTitle="with America's most trusted ID scanners"
        products={products}
      />
      <a className="anchor" id="inquire"></a>
      <LeadCapture
        sourcePage="Orlando Campaign"
        buttonText="Get Started"
        detailText="On a 2 year bundle. Exclusive offer to S.A.F.E. eligible venues."
        promptText="Order today for an additional $1,000 off"
        commentPrompt="Anything else you'd like us to know?"
      />
      <WhyPatronscan />
    </div>
  );
};

export default SafeOrlandoLandingPage;
