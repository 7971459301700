import React, { FC } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ProductCard } from '../../models/product-card';
import Img from 'gatsby-image/withIEPolyfill';
import Button from '../buttons/button';
import './product-grid.css';

interface ProductCardProps {
  products: ProductCard[];
  offeringTitle?: string;
  offeringSubTitle?: string;
  bgColor?: string;
  anchor?: string;
  ctaText?: string;
}
const ProductGrid: FC<ProductCardProps> = (props) => {
  const data = useStaticQuery(graphql`
    query {
      piBox: file(relativePath: { eq: "product-card/piBox-l.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kiosk: file(relativePath: { eq: "product-card/kiosk-center.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      honeywell: file(relativePath: { eq: "product-card/handheld_right.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      piMobile: file(relativePath: { eq: "product-card/pimobile-scresults.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const ProductGridSection = (products?: ProductCard[]) => {
    if (!products) {
      return null;
    }

    return (
      <section
        id="description"
        className="flex-row p-4 md:p-8 product-section"
        style={{ backgroundColor: props.bgColor }}
      >
        <div className="flex flex-column items-center mb-10">
          <h1 className="text-center text-grey-orlando font-bold text-5xl pdct-title">
            {props.offeringTitle}
          </h1>
          <h4 className="text-center text-grey-bluey font-bold text-lg tab:text-2xl max-w-md pdct-subtitle">
            {props.offeringSubTitle}
          </h4>
        </div>
        <div className="flex product-grid">
          {products
            ? products.map((product: ProductCard, index) => (
                <div
                  className={`bg-${
                    product.bgColor ? product.bgColor : 'white'
                  } pdct-card-container`}
                  key={index}
                >
                  <div className="product-image">
                    {product.image === 'piBox' && (
                      <Img
                        className="rounded-t-lg product-image"
                        objectFit="contain"
                        fluid={data.piBox.childImageSharp.fluid}
                      />
                    )}
                    {product.image === 'kiosk' && (
                      <Img
                        className="rounded-t-lg product-image"
                        objectFit="contain"
                        fluid={data.kiosk.childImageSharp.fluid}
                      />
                    )}
                    {product.image === 'honeywell' && (
                      <Img
                        className="rounded-t-lg product-image"
                        objectFit="contain"
                        fluid={data.honeywell.childImageSharp.fluid}
                      />
                    )}
                    {product.image === 'piMobile' && (
                      <Img
                        className="rounded-t-lg product-image"
                        objectFit="contain"
                        fluid={data.piMobile.childImageSharp.fluid}
                      />
                    )}
                  </div>
                  {product.productName && (
                    <h5 className="text-center text-black font-bold text-lg">
                      {product.productName}
                    </h5>
                  )}
                  {product.description && (
                    <p className="text-center text-grey-bluey font-bold text-md">
                      {product.description}
                    </p>
                  )}
                  {product.ctaText && (
                    <div className="pdct-button">
                      <Button
                        landing
                        buttonId={product.ctaButtonId}
                        product
                        text={product.ctaText}
                        anchor={product.anchor}
                      />
                    </div>
                  )}
                </div>
              ))
            : null}
        </div>
      </section>
    );
  };

  return <section id="product-grid">{ProductGridSection(props.products)}</section>;
};
export default ProductGrid;
