import React, { FC } from 'react';
import Img from 'gatsby-image';
import { TestimonialCard } from '../../models/testimonial-card';
import Slider from 'react-slick';
import './testimonial-slider.css';

interface TestimonialsProps {
  testimonials: TestimonialCard[];
}

const Testimonials: FC<TestimonialsProps> = (props) => {
  const testimonialSliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 7000,
    swipeToSlide: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          autoplay: false,
          swipeToSlide: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          autoplay: true,
          autoplaySpeed: 7000,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          autoplay: true,
          autoplaySpeed: 7000,
          swipeToSlide: true,
        },
      },
    ],
  };

  const TestimonialSection = (testimonials?: TestimonialCard[]) => {
    if (!testimonials) {
      return null;
    }

    return (
      <div>
        <div className="testimonial-container bg-blue-orlando">
          <Slider {...testimonialSliderSettings}>
            {testimonials
              ? testimonials.map((testimonial: TestimonialCard, index) => (
                  <div className="testimonial font-black" key={index}>
                    {testimonial.testimonialVideo && (
                      <div className="test-card-container">
                        <div className="vid-test-box">
                          <iframe
                            className="vid-test"
                            src={testimonial.testimonialVideo}
                            frameBorder="0"
                            allowFullScreen
                          ></iframe>
                        </div>
                        <div className="vid-test-text">
                          <div className="test-quote">{testimonial.quote}</div>
                          <div className="test-author text-grey-subtle">
                            - {testimonial.fullName}
                          </div>
                          <div className="test-title text-grey-subtle">
                            {testimonial.positionTitle}
                          </div>
                          <div className="test-location text-grey-subtle">
                            {testimonial.location}
                          </div>
                        </div>
                      </div>
                    )}
                    {testimonial.image && (
                      <Img className="testimonial-feature-image" fluid={testimonial.image} />
                    )}
                    {!testimonial.image && !testimonial.testimonialVideo ? (
                      <div className="text-testimonial">
                        <div className="text-quote">{testimonial.quote}</div>
                        <div className="text-author text-grey-subtle">- {testimonial.fullName}</div>
                        <div className="text-title text-grey-subtle">
                          {testimonial.positionTitle}
                        </div>
                        <div className="text-location text-grey-subtle">{testimonial.location}</div>
                      </div>
                    ) : null}
                  </div>
                ))
              : null}
          </Slider>
        </div>
      </div>
    );
  };

  return <section id="trusted-by">{TestimonialSection(props.testimonials)}</section>;
};
export default Testimonials;
