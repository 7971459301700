import React, { FC } from 'react';
import Img from 'gatsby-image';
import Button from '../../buttons/button';
import './feature-benefit.css';

interface FeatureProps {
  buttonId: string;
  rightAlign?: boolean;
  image: any;
  heading?: string;
  headingThin?: string;
  subHeading?: string;
  description?: string;
  linkText?: string;
  link?: string;
  secondLinkText?: string;
  secondLink?: string;
  externalLink?: boolean;
  backgroundColor?: string;
  textColor?: string;
  anchorLink?: string;
}

const Feature: FC<FeatureProps> = (props) => {
  const roundedClasses = props.rightAlign ? 'md:l-lg' : 'md:r-lg';
  return (
    <div
      className={`feature-container ${props.rightAlign ? 'reverse' : ''} ${
        props.backgroundColor ? `bg-${props.backgroundColor}` : ''
      }`}
    >
      <div className={`feature-photo ${roundedClasses}`}>
        <Img className={roundedClasses} fluid={props.image} />
      </div>
      <div className="text-container text-grey">
        {props.children}
        {props.heading && (
          <h2
            className={`${
              props.textColor ? `text-${props.textColor}` : 'text-blue'
            } font-heading leading-none text-5xl thick-heading`}
          >
            {props.heading}
          </h2>
        )}
        {props.headingThin && (
          <h2
            className={`${
              props.textColor ? `text-${props.textColor}` : 'text-blue'
            } leading-none font-subheading text-5xl mb-6 thin-heading`}
          >
            {props.headingThin}
          </h2>
        )}
        {props.subHeading && (
          <p
            className={`${
              props.textColor ? `text-${props.textColor}` : ' text-grey'
            } leading-tight mb-6 sub-tagline`}
          >
            {props.subHeading}
          </p>
        )}
        {props.description && (
          <p
            className={`${
              props.textColor ? `text-${props.textColor}` : 'text-grey'
            } leading-tight text-base mb-3 feature-description-text`}
          >
            {props.description}
          </p>
        )}
        <div className="fb-button-container flex flex-row justify-center">
          {props.anchorLink && (
            <Button
              className="w-full"
              buttonId={props.buttonId}
              landing
              text={props.linkText}
              anchor={props.anchorLink}
            />
          )}
          {props.link && (
            <div className="fb-button">
              <Button buttonId={props.buttonId} link={props.link} text={props.linkText} landing />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Feature;
