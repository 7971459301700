import React, { FC } from 'react';
import Img from 'gatsby-image';
import Buttons from '../../buttons/feature-buttons';
import { Route } from '../../../models/site-links';
import './feature-block.css';

interface FeatureBulletProps {
  rightAlign?: boolean;
  image: any;
  heading?: string;
  subHeading?: string;
  description?: string;
  linkText?: string;
  link?: Route;
  secondLinkText?: string;
  secondLink?: string;
  externalLink?: boolean;
  backgroundColor?: string;
  textColor?: string;
  firstBullet?: string;
  secondBullet?: string;
  thirdBullet?: string;
  fourthBullet?: string;
}

const FeatureBullet: FC<FeatureBulletProps> = (props) => {
  const roundedClasses = props.rightAlign ? 'md:l-lg' : 'md:r-lg';
  return (
    <div
      className={`max-w-none feature-container ${props.rightAlign ? 'reverse' : ''} ${
        props.backgroundColor ? `bg-${props.backgroundColor}` : ''
      }`}
    >
      <div className={`feature-photo ${roundedClasses}`}>
        <Img className={roundedClasses} fluid={props.image} />
      </div>
      <div className="text-container text-left text-grey ">
        {props.children}
        {props.heading && (
          <h2
            className={`${
              props.textColor ? `text-${props.textColor}` : 'text-blue'
            } font-heading leading-none text-5xl bullet-title w-7/8`}
          >
            {props.heading}
          </h2>
        )}
        {props.subHeading && (
          <p
            className={`${
              props.textColor ? `text-${props.textColor}` : ' text-grey'
            } leading-tight mb-6 sub-tagline`}
          >
            {props.subHeading}
          </p>
        )}
        {props.description && (
          <p
            className={`${
              props.textColor ? `text-${props.textColor}` : 'text-grey'
            } leading-tight text-base mb-3 feature-description-text`}
          >
            {props.description}
          </p>
        )}
        <div className="h-8"></div>
        {props.firstBullet && (
          <ul
            className={`${
              props.textColor ? `text-${props.textColor}` : 'text-grey'
            } font-semi text-lg bullets`}
          >
            <li className="bullet-item">{props.firstBullet}</li>
            {props.secondBullet && <li className="bullet-item">{props.secondBullet}</li>}
            {props.thirdBullet && <li className="bullet-item">{props.thirdBullet}</li>}
            {props.fourthBullet && <li className="bullet-item">{props.fourthBullet}</li>}
          </ul>
        )}
        <Buttons
          link={props.link}
          buttonId="FeatureBullet"
          linkText={props.linkText}
          secondLink={props.secondLink}
          secondLinkText={props.secondLinkText}
        />
      </div>
    </div>
  );
};

export default FeatureBullet;
